<template>

  <div class="checkout-items">
    <b-card
      v-for="product in products"
      :key="product.id"
      class="ecommerce-card"
      no-body
    >

      <!-- Product Image -->
      <div class="item-img">
        <b-link>
          <b-img
            :src="product.photo.thumb_url"
            :alt="`${product.name}-${product.id}`"
          />
        </b-link>
      </div>

      <!-- Product Details: Card Body -->
      <b-card-body>
        <div class="item-name">
          <h6 class="mb-1">
            <b-link class="text-body">
              {{ product.name }}
            </b-link>
          </h6>
        </div>
        <span class="text-secondary mb-1">Kategori: {{ product.category_name }}</span>
        <span class="text-secondary mb-1">Ürün birimi: {{ product.unit }}</span>
        <span class="text-secondary mb-1">Stok kodu: {{ product.code }}</span>
        <div class="item-quantity">
          <span class="quantity-title">Adet/Birim:</span>
          <b-form-spinbutton
            v-model="product.qty"
            size="sm"
            class="ml-75"
            inline
            @change="SET_QTY(product)"
          />
        </div>
      </b-card-body>

      <!-- Product Options/Actions -->
      <div class="item-options text-center">
        <div class="item-wrapper">
          <div class="item-cost">
            <h4 class="item-price">
              ₺{{ product.list_price }}
            </h4>
          </div>
        </div>
        <b-button
          variant="light"
          class="mt-1 remove-wishlist"
          @click="SET_CART({val: product, set: false})"
        >
          <feather-icon
            icon="XIcon"
            class="mr-50"
          />
          <span>Sil</span>
        </b-button>
      </div>
    </b-card>
  </div>

</template>

<script>
import {
  BCard, BCardBody, BLink, BImg, BButton, BFormSpinbutton,
} from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    BCard, BCardBody, BLink, BImg, BButton, BFormSpinbutton,
  },
  data() {
    return {
      formatDate,
    }
  },
  computed: {
    ...mapGetters('product', {
      products: 'getCartProducts',
    }),
  },
  methods: {
    ...mapMutations('product', {
      SET_CART: 'SET_CART',
      SET_QTY: 'SET_QTY',
    }),
  },
}
</script>

<style>

</style>
