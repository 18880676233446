<template>
  <div class="list-view product-checkout mt-0">

    <!-- Products List -->
    <e-commerce-checkout-step-cart-products />

    <!-- Checkout Options -->
    <div class="checkout-options">
      <b-card>
        <div class="price-details">
          <h6 class="price-title">
            Detaylar
          </h6>
          <ul class="list-unstyled">
            <li class="price-detail">
              <div class="detail-title">
                Toplam Fiyat
              </div>
              <div class="detail-amt">
                ₺{{ getTotals.totalPrice }}
              </div>
            </li>
            <!-- <li class="price-detail">
              <div class="detail-title">
                Bag Discount
              </div>
              <div class="detail-amt discount-amt text-success">
                -25$
              </div>
            </li>
            <li class="price-detail">
              <div class="detail-title">
                Estimated Tax
              </div>
              <div class="detail-amt">
                $1.3
              </div>
            </li>
            <li class="price-detail">
              <div class="detail-title">
                EMI Eligibility
              </div>
              <a
                href="javascript:void(0)"
                class="detail-amt text-primary"
              >Details</a>
            </li> -->
            <li class="price-detail">
              <div class="detail-title">
                Toplam Ürün
              </div>
              <div class="detail-amt discount-amt">
                {{ getTotals.totalQty }}
              </div>
            </li>
          </ul>
          <ul class="list-unstyled">
            <!-- <li class="price-detail">
              <div class="detail-title detail-total">
                Total
              </div>
              <div class="detail-amt font-weight-bolder">
                $574
              </div>
            </li> -->
          </ul>
          <b-button
            variant="primary"
            block
            :disabled="getCartProducts.length === 0"
            @click="addInvoce"
          >
            Sipariş Oluştur
          </b-button>
        </div>

      </b-card>
    </div>
    <b-modal
      id="modal-order"
      centered
      hide-footer
      title="Kullanıcı Bilgileri"
    >
      <b-form @submit.prevent="addOrder">
        <b-form-group>
          <label for="email">İsim:</label>
          <b-form-input
            id="email"
            v-model="form.name"
            type="text"
            required
            placeholder="İsim ve soyisim"
          />
        </b-form-group>
        <b-form-group>
          <label for="email">Email:</label>
          <b-form-input
            id="email"
            v-model="form.email"
            type="email"
            required
            placeholder="Email adresi"
          />
        </b-form-group>
        <b-form-group>
          <label for="phone">Telefon:</label>
          <b-form-input
            id="phone"
            v-model="form.phone"
            type="phone"
            required
            placeholder="Telefon numarası"
          />
        </b-form-group>
        <b-button
          variant="primary"
          block
          type="submit"
        >
          Siparişi Oluştur
        </b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BCard, BForm, BFormInput, BFormGroup, BModal,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ECommerceCheckoutStepCartProducts from './ECommerceCheckoutStepCartProducts.vue'

export default {
  components: {
    // BSV
    BButton,
    BCard,

    // SFC
    ECommerceCheckoutStepCartProducts,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BForm,
    BFormInput,
    BFormGroup,
    BModal,
  },
  data() {
    return {
      form: {
        email: null,
        phone: null,
        name: null,
      },
    }
  },
  computed: {
    ...mapGetters('product', ['getTotals', 'getCartProducts']),
  },
  methods: {
    ...mapActions('product', ['setInvoce', 'setOrder']),
    async addInvoce() {
      if (this.$store.getters['auth/IS_LOGIN']) {
        await this.setInvoce().then(res => {
          if (res.status === 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                icon: 'CheckIcon',
                text: 'İşleminiz başarılı bir şekilde gerçekleşti. En kısa sürede sizinle iletişime geçilecektir.😉',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'products' })
          }
        })
      } else {
        this.$bvModal.show('modal-order')
      }
    },
    async addOrder() {
      await this.setOrder(this.form).then(res => {
        if (res.status === 201) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Başarılı',
              icon: 'CheckIcon',
              text: 'İşleminiz başarılı bir şekilde gerçekleşti. En kısa sürede sizinle iletişime geçilecektir.😉',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'products' })
        }
      })
    },
  },
}
</script>
